
/* You can add global styles to this file, and also import other style files */
@import "~balloon-css/balloon";
:root {
  /* // --balloon-color: rgb(200, 200, 200); //random gray
  // --balloon-color: #b2f5ea; // teal-200 */
  --balloon-color: #cbd5e0; /* gray-400 */
}
/* // https://stephanwagner.me/only-css-loading-spinner */

html{
  background-color: #F7FBFC;
}

@keyframes spinner {
    to {transform: rotate(360deg);}
  }
   
  .spinner-1-lg:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-left: -50px;
    border-radius: 50%;
    border-top: 4px solid #5a67d8;
    border-right: 4px solid transparent;
    animation: spinner .6s linear infinite;
    z-index: 10;
  }

  .spinner-1-sm:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    margin-left: -25px;
    border-radius: 50%;
    border-top: 4px solid #5a67d8;
    border-right: 4px solid transparent;
    animation: spinner .6s linear infinite;
    z-index: 10;
  }

  .spinner-1-xs:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 24px;
    height: 24px;
    margin-top: -12px;
    margin-left: -12px;
    border-radius: 50%;
    border-top: 4px solid #5a67d8;
    border-right: 4px solid transparent;
    animation: spinner .6s linear infinite;
    z-index: 10;
  }

  .spinner-2:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-left: -50px;
    border-radius: 50%;
    border: 5px solid #f6f;
    border-top-color: #0e0;
    border-right-color: #0dd;
    border-bottom-color: #f90;
    animation: spinner .6s linear infinite;
    z-index: 10;
  }

  .spinner-3:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-left: -50px;
    border-radius: 50%;
    border: 5px solid #ccc;
    border-top-color: #07d;
    animation: spinner .6s linear infinite;
    z-index: 10;
  }

  .spinner-4:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-left: -50px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: rgb(114, 255, 213);
    border-right-color: #0dd;
    border-bottom-color: rgb(1, 112, 202);
    animation: spinner .6s linear infinite;
    z-index: 10;
  }

  .spinner-4:after{
    background-color: white;
    opacity: 0.5;
    z-index: 5;
  }

  .js-plotly-plot .plotly .modebar {
    position: absolute;
    display:flex;
    flex-direction: column;
    top: 2px;
    right: 2px;
}

.rotate {

  transform: rotate(-90deg);


  /* Legacy vendor prefixes that you probably don't need... */

  /* Safari */
  -webkit-transform: rotate(-90deg);

  /* Firefox */
  -moz-transform: rotate(-90deg);

  /* IE */
  -ms-transform: rotate(-90deg);

  /* Opera */
  -o-transform: rotate(-90deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

}

.highlight-username {
  background: -webkit-linear-gradient(#ed64a6, #805ad5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.highlight-username:hover {
  background: -webkit-linear-gradient(#569AFF, #805ad5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-tribified-pink-purple{
  background: linear-gradient(to right, #CB5EEE, #667EEA)
}


.h-30 {
  height: 7.5rem;
}

.ngx-slider .ngx-slider-pointer:focus{
  outline: none
}

.ngx-slider .ngx-slider-pointer {
  background: gray
}

::-webkit-scrollbar{
  width: 5px;
}

::-webkit-scrollbar-track {
  /* indigo-100 */
  background-color: rgba(235, 244, 255,1); 
  width: 3px;
}

::-webkit-scrollbar-thumb{
  /* indigo-600 */
  background-color: rgba(90, 103, 216,0.5);
  width: 3px;
}

.max-height{
  height: 90vh
}

.shimmer {
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  background: rgba(242, 246, 252,1);
  background-image: linear-gradient(to right, rgba(242, 246, 252,0), white, rgba(242, 246, 252,0.4), rgba(242, 246, 252,1));
  background-repeat: no-repeat;
  background-size: 800px 100%;
  height: 100%;
  position: relative;
}

@keyframes placeHolderShimmer{
  
  0% {
      background-position: -468px 0;
  }
  100% {
      background-position: 468px 0;
  }
  }

.md-overlay-text {
  font-size: clamp(0.75rem, 3vw, 2rem);
}

/* .ngx-slider-active::after {
  background-color: white;
} */


/* .module-border-wrap {
  max-width: 250px;
  padding: 1rem;
  position: relative;
  background: linear-gradient(to right, red, purple);
  padding: 2px;
} */
