*,
::before,
::after {
  box-sizing: border-box;
}

html {
  -moz-tab-size: 4;
  tab-size: 4;
}

html {
  line-height: 1.15;
  
  -webkit-text-size-adjust: 100%;
  
}

body {
  margin: 0;
}

body {
  font-family:
		system-ui,
		-apple-system, 
		'Segoe UI',
		Roboto,
		Helvetica,
		Arial,
		sans-serif,
		'Apple Color Emoji',
		'Segoe UI Emoji';
}

b {
  font-weight: bolder;
}

code,
pre {
  font-family:
		ui-monospace,
		SFMono-Regular,
		Consolas,
		'Liberation Mono',
		Menlo,
		monospace;
  
  font-size: 1em;
  
}

small {
  font-size: 80%;
}

sub {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

table {
  text-indent: 0;
  
  border-color: inherit;
  
}

button,
input,
select,
textarea {
  font-family: inherit;
  
  font-size: 100%;
  
  line-height: 1.15;
  
  margin: 0;
  
}

button,
select {
  
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  
  outline-offset: -2px;
  
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  
  font: inherit;
  
}

dl,
dd,
h2,
h3,
h4,
figure,
p,
pre {
  margin: 0;
}

button {
  background-color: transparent;
  background-image: none;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

html {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  
  line-height: 1.5;
  
}

body {
  font-family: inherit;
  line-height: inherit;
}

*,
::before,
::after {
  box-sizing: border-box;
  
  border-width: 0;
  
  border-style: solid;
  
  border-color: currentColor;
  
}

img {
  border-style: solid;
}

textarea {
  resize: vertical;
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #cbd5e0;
}

button,
[role="button"] {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

h2,
h3,
h4 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

button,
input,
select,
textarea {
  padding: 0;
  line-height: inherit;
  color: inherit;
}

pre,
code {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

img,
svg,
video,
canvas,
iframe,
object {
  display: block;
  
  vertical-align: middle;
  
}

img,
video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, ::before, ::after {
  border-color: currentColor;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-3 {
  grid-column-start: 3;
}

.row-span-1 {
  grid-row: span 1 / span 1;
}

.row-span-3 {
  grid-row: span 3 / span 3;
}

.row-start-1 {
  grid-row-start: 1;
}

.row-start-2 {
  grid-row-start: 2;
}

.row-start-3 {
  grid-row-start: 3;
}

.m-1 {
  margin: 0.25rem;
}

.m-2 {
  margin: 0.5rem;
}

.m-3 {
  margin: 0.75rem;
}

.m-4 {
  margin: 1rem;
}

.-m-3 {
  margin: -0.75rem;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.-mx-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.-my-1 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}

.-my-2 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.-my-5 {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-40 {
  margin-top: 10rem;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.-mr-2 {
  margin-right: -0.5rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.-mb-8 {
  margin-bottom: -2rem;
}

.-mb-px {
  margin-bottom: -1px;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.ml-auto {
  margin-left: auto;
}

.-ml-1 {
  margin-left: -0.25rem;
}

.-ml-px {
  margin-left: -1px;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.flow-root {
  display: flow-root;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-16 {
  height: 4rem;
}

.h-20 {
  height: 5rem;
}

.h-32 {
  height: 8rem;
}

.h-48 {
  height: 12rem;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.min-h-screen {
  min-height: 100vh;
}

.w-0 {
  width: 0;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-16 {
  width: 4rem;
}

.w-20 {
  width: 5rem;
}

.w-32 {
  width: 8rem;
}

.w-48 {
  width: 12rem;
}

.w-64 {
  width: 16rem;
}

.w-auto {
  width: auto;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/4 {
  width: 25%;
}

.w-3\/4 {
  width: 75%;
}

.w-1\/5 {
  width: 20%;
}

.w-11\/12 {
  width: 91.666667%;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-0 {
  min-width: 0;
}

.min-w-full {
  min-width: 100%;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-initial {
  flex: 0 1 auto;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.origin-top-right {
  transform-origin: top right;
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0;
}

.translate-x-5 {
  --tw-translate-x: 1.25rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

.cursor-pointer {
  cursor: pointer;
}

.list-inside {
  list-style-position: inside;
}

.list-decimal {
  list-style-type: decimal;
}

.appearance-none {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(237, 242, 247, var(--tw-divide-opacity));
}

.self-center {
  align-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-l-md {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.rounded-tr-full {
  border-top-right-radius: 9999px;
}

.rounded-br-full {
  border-bottom-right-radius: 9999px;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-t-4 {
  border-top-width: 4px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b-4 {
  border-bottom-width: 4px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-l-8 {
  border-left-width: 8px;
}

.border-dashed {
  border-style: dashed;
}

.border-transparent {
  border-color: transparent;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(237, 242, 247, var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(226, 232, 240, var(--tw-border-opacity));
}

.border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 178, 178, var(--tw-border-opacity));
}

.border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 62, 62, var(--tw-border-opacity));
}

.border-orange-300 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 211, 141, var(--tw-border-opacity));
}

.border-yellow-300 {
  --tw-border-opacity: 1;
  border-color: rgba(250, 240, 137, var(--tw-border-opacity));
}

.border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgba(154, 230, 180, var(--tw-border-opacity));
}

.border-teal-300 {
  --tw-border-opacity: 1;
  border-color: rgba(94, 234, 212, var(--tw-border-opacity));
}

.border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgba(144, 205, 244, var(--tw-border-opacity));
}

.border-indigo-300 {
  --tw-border-opacity: 1;
  border-color: rgba(163, 191, 250, var(--tw-border-opacity));
}

.border-indigo-400 {
  --tw-border-opacity: 1;
  border-color: rgba(127, 156, 245, var(--tw-border-opacity));
}

.border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgba(102, 126, 234, var(--tw-border-opacity));
}

.border-indigo-800 {
  --tw-border-opacity: 1;
  border-color: rgba(67, 65, 144, var(--tw-border-opacity));
}

.border-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgba(214, 188, 250, var(--tw-border-opacity));
}

.border-pink-300 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 182, 206, var(--tw-border-opacity));
}

.hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(226, 232, 240, var(--tw-border-opacity));
}

.focus\:border-gray-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(226, 232, 240, var(--tw-border-opacity));
}

.focus\:border-gray-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(26, 32, 44, var(--tw-border-opacity));
}

.focus\:border-red-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(229, 62, 62, var(--tw-border-opacity));
}

.focus\:border-red-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(197, 48, 48, var(--tw-border-opacity));
}

.focus\:border-teal-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(19, 78, 74, var(--tw-border-opacity));
}

.focus\:border-blue-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(144, 205, 244, var(--tw-border-opacity));
}

.focus\:border-blue-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(43, 108, 176, var(--tw-border-opacity));
}

.focus\:border-indigo-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(163, 191, 250, var(--tw-border-opacity));
}

.focus\:border-indigo-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(76, 81, 191, var(--tw-border-opacity));
}

.bg-transparent {
  background-color: transparent;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 242, 247, var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(203, 213, 224, var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(160, 174, 192, var(--tw-bg-opacity));
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(74, 85, 104, var(--tw-bg-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(45, 55, 72, var(--tw-bg-opacity));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 245, 245, var(--tw-bg-opacity));
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 215, 215, var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 101, 101, var(--tw-bg-opacity));
}

.bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 250, 240, var(--tw-bg-opacity));
}

.bg-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 235, 200, var(--tw-bg-opacity));
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 240, var(--tw-bg-opacity));
}

.bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 252, 191, var(--tw-bg-opacity));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 255, 244, var(--tw-bg-opacity));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(198, 246, 213, var(--tw-bg-opacity));
}

.bg-teal-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(204, 251, 241, var(--tw-bg-opacity));
}

.bg-teal-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 246, 228, var(--tw-bg-opacity));
}

.bg-teal-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(45, 212, 191, var(--tw-bg-opacity));
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(235, 248, 255, var(--tw-bg-opacity));
}

.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 227, 248, var(--tw-bg-opacity));
}

.bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 179, 237, var(--tw-bg-opacity));
}

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(43, 108, 176, var(--tw-bg-opacity));
}

.bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(235, 244, 255, var(--tw-bg-opacity));
}

.bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(195, 218, 254, var(--tw-bg-opacity));
}

.bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(127, 156, 245, var(--tw-bg-opacity));
}

.bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(90, 103, 216, var(--tw-bg-opacity));
}

.bg-indigo-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 65, 144, var(--tw-bg-opacity));
}

.bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 245, 255, var(--tw-bg-opacity));
}

.bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 216, 253, var(--tw-bg-opacity));
}

.bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 245, 247, var(--tw-bg-opacity));
}

.bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 215, 226, var(--tw-bg-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 242, 247, var(--tw-bg-opacity));
}

.hover\:bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}

.hover\:bg-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(160, 174, 192, var(--tw-bg-opacity));
}

.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(74, 85, 104, var(--tw-bg-opacity));
}

.hover\:bg-gray-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(26, 32, 44, var(--tw-bg-opacity));
}

.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 130, 206, var(--tw-bg-opacity));
}

.hover\:bg-indigo-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(235, 244, 255, var(--tw-bg-opacity));
}

.hover\:bg-indigo-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(102, 126, 234, var(--tw-bg-opacity));
}

.hover\:bg-indigo-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(90, 103, 216, var(--tw-bg-opacity));
}

.hover\:bg-indigo-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 81, 191, var(--tw-bg-opacity));
}

.focus\:bg-gray-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
}

.focus\:bg-gray-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(113, 128, 150, var(--tw-bg-opacity));
}

.focus\:bg-gray-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(74, 85, 104, var(--tw-bg-opacity));
}

.focus\:bg-indigo-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(90, 103, 216, var(--tw-bg-opacity));
}

.bg-opacity-50 {
  --tw-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --tw-bg-opacity: 0.75;
}

.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--gradient-color-stops));
}

.from-red-500 {
  --tw-gradient-from: #f56565;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 101, 101, 0));
}

.via-pink-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #f687b3, var(--tw-gradient-to, rgba(246, 135, 179, 0));
}

.to-indigo-500 {
  --tw-gradient-to: #667eea;
}

.fill-current {
  fill: currentColor;
}

.object-cover {
  object-fit: cover;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pr-0 {
  padding-right: 0;
}

.pr-3 {
  padding-right: 0.75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pl-3 {
  padding-left: 0.75rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.text-xs {
  font-size: 0.75rem;
}

.text-sm {
  font-size: 0.875rem;
}

.text-base {
  font-size: 1rem;
}

.text-lg {
  font-size: 1.125rem;
}

.text-xl {
  font-size: 1.25rem;
}

.text-2xl {
  font-size: 1.5rem;
}

.text-3xl {
  font-size: 1.875rem;
}

.text-4xl {
  font-size: 2.25rem;
}

.text-5xl {
  font-size: 3rem;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.leading-3 {
  line-height: .75rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-none {
  line-height: 1;
}

.tracking-tight {
  letter-spacing: -0.025em;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(226, 232, 240, var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(203, 213, 224, var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(160, 174, 192, var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(113, 128, 150, var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(74, 85, 104, var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(45, 55, 72, var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(26, 32, 44, var(--tw-text-opacity));
}

.text-red-300 {
  --tw-text-opacity: 1;
  color: rgba(254, 178, 178, var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgba(252, 129, 129, var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(245, 101, 101, var(--tw-text-opacity));
}

.text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgba(246, 224, 94, var(--tw-text-opacity));
}

.text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgba(183, 121, 31, var(--tw-text-opacity));
}

.text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgba(151, 90, 22, var(--tw-text-opacity));
}

.text-teal-400 {
  --tw-text-opacity: 1;
  color: rgba(45, 212, 191, var(--tw-text-opacity));
}

.text-teal-500 {
  --tw-text-opacity: 1;
  color: rgba(20, 184, 166, var(--tw-text-opacity));
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgba(99, 179, 237, var(--tw-text-opacity));
}

.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgba(43, 108, 176, var(--tw-text-opacity));
}

.text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgba(195, 218, 254, var(--tw-text-opacity));
}

.text-indigo-300 {
  --tw-text-opacity: 1;
  color: rgba(163, 191, 250, var(--tw-text-opacity));
}

.text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgba(102, 126, 234, var(--tw-text-opacity));
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgba(90, 103, 216, var(--tw-text-opacity));
}

.text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgba(76, 81, 191, var(--tw-text-opacity));
}

.text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgba(67, 65, 144, var(--tw-text-opacity));
}

.text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgba(60, 54, 107, var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.hover\:text-gray-200:hover {
  --tw-text-opacity: 1;
  color: rgba(237, 242, 247, var(--tw-text-opacity));
}

.hover\:text-gray-400:hover {
  --tw-text-opacity: 1;
  color: rgba(203, 213, 224, var(--tw-text-opacity));
}

.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgba(160, 174, 192, var(--tw-text-opacity));
}

.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgba(113, 128, 150, var(--tw-text-opacity));
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgba(74, 85, 104, var(--tw-text-opacity));
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgba(26, 32, 44, var(--tw-text-opacity));
}

.hover\:text-red-500:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 101, 101, var(--tw-text-opacity));
}

.hover\:text-yellow-500:hover {
  --tw-text-opacity: 1;
  color: rgba(236, 201, 75, var(--tw-text-opacity));
}

.hover\:text-yellow-600:hover {
  --tw-text-opacity: 1;
  color: rgba(214, 158, 46, var(--tw-text-opacity));
}

.hover\:text-blue-300:hover {
  --tw-text-opacity: 1;
  color: rgba(144, 205, 244, var(--tw-text-opacity));
}

.hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgba(49, 130, 206, var(--tw-text-opacity));
}

.hover\:text-indigo-100:hover {
  --tw-text-opacity: 1;
  color: rgba(235, 244, 255, var(--tw-text-opacity));
}

.hover\:text-indigo-200:hover {
  --tw-text-opacity: 1;
  color: rgba(195, 218, 254, var(--tw-text-opacity));
}

.hover\:text-indigo-400:hover {
  --tw-text-opacity: 1;
  color: rgba(127, 156, 245, var(--tw-text-opacity));
}

.hover\:text-indigo-500:hover {
  --tw-text-opacity: 1;
  color: rgba(102, 126, 234, var(--tw-text-opacity));
}

.hover\:text-indigo-600:hover {
  --tw-text-opacity: 1;
  color: rgba(90, 103, 216, var(--tw-text-opacity));
}

.hover\:text-indigo-700:hover {
  --tw-text-opacity: 1;
  color: rgba(76, 81, 191, var(--tw-text-opacity));
}

.focus\:text-white:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.focus\:text-gray-500:focus {
  --tw-text-opacity: 1;
  color: rgba(160, 174, 192, var(--tw-text-opacity));
}

.focus\:text-gray-700:focus {
  --tw-text-opacity: 1;
  color: rgba(74, 85, 104, var(--tw-text-opacity));
}

.focus\:text-gray-900:focus {
  --tw-text-opacity: 1;
  color: rgba(26, 32, 44, var(--tw-text-opacity));
}

.focus\:text-indigo-800:focus {
  --tw-text-opacity: 1;
  color: rgba(67, 65, 144, var(--tw-text-opacity));
}

.text-opacity-75 {
  --tw-text-opacity: 0.75;
}

.underline {
  text-decoration: underline;
}

.hover\:underline:hover {
  text-decoration: underline;
}

.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
}

.placeholder-gray-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(160, 174, 192, var(--tw-placeholder-opacity));
}

.focus\:placeholder-gray-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(203, 213, 224, var(--tw-placeholder-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

*, ::before, ::after {
  --tw-shadow: 0 0 #0000;
}

.shadow-xs {
  --tw-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-outline:focus {
  --tw-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

*, ::before, ::after {
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(66, 153, 225, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-gray-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(226, 232, 240, var(--tw-ring-opacity));
}

.focus\:ring-gray-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(26, 32, 44, var(--tw-ring-opacity));
}

.focus\:ring-green-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(56, 161, 105, var(--tw-ring-opacity));
}

.focus\:ring-indigo-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(102, 126, 234, var(--tw-ring-opacity));
}

.focus\:ring-indigo-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(67, 65, 144, var(--tw-ring-opacity));
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.filter {
  --tw-blur: var(--tw-empty, );
  --tw-brightness: var(--tw-empty, );
  --tw-contrast: var(--tw-empty, );
  --tw-grayscale: var(--tw-empty, );
  --tw-hue-rotate: var(--tw-empty, );
  --tw-invert: var(--tw-empty, );
  --tw-saturate: var(--tw-empty, );
  --tw-sepia: var(--tw-empty, );
  --tw-drop-shadow: var(--tw-empty, );
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-all {
  transition-property: all;
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke;
}

.transition-opacity {
  transition-property: opacity;
}

.duration-150 {
  transition-duration: 150ms;
}

.duration-200 {
  transition-duration: 200ms;
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@media (min-width: 640px) {

  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:mt-4 {
    margin-top: 1rem;
  }

  .sm\:mt-5 {
    margin-top: 1.25rem;
  }

  .sm\:mt-8 {
    margin-top: 2rem;
  }

  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:ml-1 {
    margin-left: 0.25rem;
  }

  .sm\:ml-3 {
    margin-left: 0.75rem;
  }

  .sm\:ml-4 {
    margin-left: 1rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:inline-block {
    display: inline-block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:inline-flex {
    display: inline-flex;
  }

  .sm\:grid {
    display: grid;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:h-12 {
    height: 3rem;
  }

  .sm\:h-56 {
    height: 14rem;
  }

  .sm\:h-screen {
    height: 100vh;
  }

  .sm\:w-4 {
    width: 1rem;
  }

  .sm\:w-12 {
    width: 3rem;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:max-w-lg {
    max-width: 32rem;
  }

  .sm\:max-w-4xl {
    max-width: 56rem;
  }

  .sm\:flex-1 {
    flex: 1 1 0%;
  }

  .sm\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .sm\:flex-col {
    flex-direction: column;
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:gap-4 {
    gap: 1rem;
  }

  .sm\:rounded-md {
    border-radius: 0.375rem;
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:p-1 {
    padding: 0.25rem;
  }

  .sm\:p-2 {
    padding: 0.5rem;
  }

  .sm\:p-3 {
    padding: 0.75rem;
  }

  .sm\:p-4 {
    padding: 1rem;
  }

  .sm\:p-5 {
    padding: 1.25rem;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .sm\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .sm\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .sm\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .sm\:pt-8 {
    padding-top: 2rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-center {
    text-align: center;
  }

  .sm\:align-middle {
    vertical-align: middle;
  }

  .sm\:text-sm {
    font-size: 0.875rem;
  }

  .sm\:text-base {
    font-size: 1rem;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
  }

  .sm\:text-5xl {
    font-size: 3rem;
  }

  .sm\:leading-5 {
    line-height: 1.25rem;
  }

  .sm\:leading-none {
    line-height: 1;
  }
}

@media (min-width: 768px) {

  .md\:order-1 {
    order: 1;
  }

  .md\:order-2 {
    order: 2;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .md\:col-start-1 {
    grid-column-start: 1;
  }

  .md\:col-start-4 {
    grid-column-start: 4;
  }

  .md\:col-start-5 {
    grid-column-start: 5;
  }

  .md\:col-start-9 {
    grid-column-start: 9;
  }

  .md\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .md\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .md\:row-start-1 {
    grid-row-start: 1;
  }

  .md\:row-start-2 {
    grid-row-start: 2;
  }

  .md\:row-start-3 {
    grid-row-start: 3;
  }

  .md\:row-start-4 {
    grid-row-start: 4;
  }

  .md\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mt-2 {
    margin-top: 0.5rem;
  }

  .md\:mt-5 {
    margin-top: 1.25rem;
  }

  .md\:ml-6 {
    margin-left: 1.5rem;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-8 {
    height: 2rem;
  }

  .md\:w-8 {
    width: 2rem;
  }

  .md\:w-1\/5 {
    width: 20%;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:max-w-3xl {
    max-width: 48rem;
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .md\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:gap-x-4 {
    column-gap: 1rem;
  }

  .md\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .md\:pb-0 {
    padding-bottom: 0;
  }

  .md\:text-sm {
    font-size: 0.875rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
  }

  .md\:text-6xl {
    font-size: 4rem;
  }

  .md\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

@media (min-width: 1024px) {

  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .lg\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .lg\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:ml-6 {
    margin-left: 1.5rem;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:h-64 {
    height: 16rem;
  }

  .lg\:max-w-xs {
    max-width: 20rem;
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:gap-6 {
    gap: 1.5rem;
  }

  .lg\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .lg\:border-r-2 {
    border-right-width: 2px;
  }

  .lg\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .lg\:pb-8 {
    padding-bottom: 2rem;
  }

  .lg\:pb-16 {
    padding-bottom: 4rem;
  }
}

@media (min-width: 1280px) {

  .xl\:hidden {
    display: none;
  }
}